<template>
  <div class="header">
    <div class="header__body">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="header__content text-center">
              <div class="header__icon">
                <span class="btn btn-primary btn-icon-only">
                  <i class="icon icon-done"></i>
                </span>
              </div>

              <h1 class="header__title h2">
                {{ $t("order.thanks") }}
                <br />
                {{ $t("order.order-received") }}
              </h1>

              <div class="header__intro text-lg">
                <p>
                  <template v-if="this.$route.params.orderNumber">
                    {{ $t("order.order-number") }} {{ this.$route.params.orderNumber }}
                    <br class="d-none d-lg-block" />
                  </template>
                  {{ $t("order.order-confirmation") }}
                </p>
              </div>

              <div class="header__actions actions">
                <div class="btn-group d-flex flex-wrap justify-content-center">
                  <router-link
                    :to="{
                      name: 'Order',
                      params: {
                        orderSlug: this.$route.params.orderNumber,
                      },
                    }"
                    class="btn btn-secondary"
                    v-if="this.$route.params.orderNumber"
                  >
                    <span v-t="'order.view-order'">View order</span>
                    <i class="icon icon-keyboard_arrow_right"></i>
                  </router-link>

                  <router-link
                    :to="{ name: 'Products' }"
                    class="btn btn-sm btn-empty btn-empty-primary"
                  >
                    <span v-t="'shop.cart-popup.continue'"
                      >Continue shopping</span
                    >
                    <i class="icon icon-keyboard_arrow_right"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  // props: ,
  // created() {}
};
</script>

<style lang="css" scoped></style>
