<template>
  <div class="header">
    <div class="header__body">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="header__content text-center">
              <div class="header__icon">
                <span class="btn btn-primary btn-icon-only">
                  <i class="icon icon-description"></i>
                </span>
              </div>

              <h1 class="header__title h2" v-t="'shop.quote.thanks-title'">
                Thank you for your quote request!
              </h1>

              <div class="header__intro text-lg">
                <p>
                  {{ $t("shop.quote.request-number") }}
                  {{ this.$route.params.quoteNumber }}
                  <br class="d-none d-lg-block" />
                  {{ $t("shop.quote.confirmation") }}
                </p>
              </div>

              <div class="header__actions actions">
                <div class="btn-group d-flex flex-wrap justify-content-center">
                  <router-link
                    :to="{ name: 'Products' }"
                    class="btn btn-sm btn-empty btn-empty-primary"
                  >
                    <span v-t="'shop.cart-popup.continue'"
                      >Continue shopping</span
                    >
                    <i class="icon icon-keyboard_arrow_right"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {}
  // props: ,
  // created() {}
};
</script>

<style lang="css" scoped></style>
