<template>
  <div class="header" v-if="$route.meta.header.title">
    <div class="header__body">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="header__content text-center">
              <h1 class="header__title h2">
                {{ $t($route.meta.header.title) }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  created() {}
};
</script>

<style lang="css" scoped></style>
